import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Observable, map, shareReplay } from 'rxjs';
import { PlaceResults, PlaceSuggestion } from '../../models/place-suggestion.model';
import { PlaceDetail } from '../../models/place-detail.model';
import { OrderEntryService } from '../../../order-entry/order-entry.service';
import { AppStateService } from '../../../app-state.service';
import { PREVENTS_SAVE_REQUEST } from '../../../app.component';

@Injectable()
export class PlacesService {
  private get placesUrl(): string {
    return this.appState.referenceData._links.places.href;
  }

  constructor(
    private appState: AppStateService,
    private http: HttpClient,
    public orderEntryService: OrderEntryService
  ) {}

  getPlaceSuggestions(query: string, customerId: string = '', labId: string): Observable<PlaceSuggestion[]> {
    const httpOptions = {
      params: this.createSuggestionParams(query, customerId),
      headers: this.appState.addTraceHeader(new HttpHeaders()),
      context: new HttpContext().set(PREVENTS_SAVE_REQUEST, true),
    };

    return this.http.get<PlaceResults>(this.placesUrl, httpOptions).pipe(
      shareReplay(),
      map((response) => {
        return response.results;
      })
    );
  }

  getPlaceDetails(placeSuggestion: PlaceSuggestion): Observable<PlaceDetail> {
    const placeDetailUrl: string = placeSuggestion._links.self.href;

    return this.http
      .get<PlaceDetail>(placeDetailUrl, {
        headers: this.appState.addTraceHeader(new HttpHeaders()),
        context: new HttpContext().set(PREVENTS_SAVE_REQUEST, true),
      })
      .pipe(
        shareReplay(),
        map((response) => {
          return response;
        })
      );
  }

  createSuggestionParams(query: string, customerId: string): any {
    const params: any = {
      searchInput: query,
    };

    if (customerId) {
      params.customerId = customerId;
    }

    return params;
  }
}
